<template>
    <main class="main-container">
      <div class="w-full relative" v-if="['active', 'purchase', 'purchased'].includes(detail.status)">
        <!-- <img src="@/assets/images/banner.png" alt="" class="w-full h-full"> -->
        <img :src="detail.voucher.voucher_image_url" alt="" class="w-full h-full">
        <!-- <img src="@/assets/images/icon-close.svg" alt="" class="w-8 h-8 absolute top-5 right-5 cursor-pointer"> -->
      </div>
      <section class="info info--sk">
        <div class="card-info card-info--sk" v-if="['active', 'purchase', 'purchased'].includes(detail.status)">
            <h1 class="card-info__heading text-2xl mt-[40px]">{{ detail.voucher.voucher_name }}</h1>

            <div class="card-info__body">
                <div class="flex justify-between items-center text-base pb-4 mb-4 border-[#EDEDED] border-b border-dashed">
                    <span class="text-gray">Masa Berlaku</span>
                    <!-- <span class="text-brand">10 Oktober 2022,11:00</span> -->
                    <a class="text-brand" id="expired">{{ detail.expired_at_str }}</a>
                </div>
                <div class="pb-4">
                    <p class="text-gray pb-0">ID Voucher</p>
                    <div class="flex justify-between items-center text-base font-semibold p-3">
                        <span class="">{{ detail.voucher_id }}</span>
                        <img @click="clickToCopy(detail.voucher_id)" src="@/assets/images/icon-copy.svg" class="w-6 h-6 cursor-pointer">
                    </div>
                </div>
                <div class="pb-4">
                    <p class="text-gray pb-0">Kode Voucher</p>
                    <div class="flex justify-between items-center text-base font-semibold p-3">
                        <span class="" id="voucher-redeemed">{{ detail.code }}</span>
                        <img @click="clickToCopy(detail.code)" src="@/assets/images/icon-copy.svg" class="w-6 h-6 cursor-pointer">
                    </div>
                </div>

                <!-- <a class="text-brand" id="terms-conditions">Lihat syarat dan ketentuan</a> -->

                <div class="mt-6 p-6 rounded-2xl shadow-[0_20px_30px_0px_rgba(231,226,244,0.25)]">
                    <div class="flex justify-between items-center">
                        <div class="flex justify-between items-center gap-2">
                            <img src="@/assets/images/icon-qrcode.svg" class="w-6 h-6">
                            <span class="font-semibold">QR Code</span>
                        </div>
                        <img src="@/assets/images/icon-arrow-up.svg" class="w-6 h-6 cursor-pointer" ref="dropdown-qrcode" @click="show('qrcode')" :class="{ 'rotate-180': showState.qrcode }">
                    </div>
                    <div class="flex flex-col text-center gap-2 mt-2" ref="img-qrcode" :class="{ hidden: showState.qrcode }">
                      <img :src="detail.image_qr">
                      <span class="font-normal text-xs">{{ detail.code }}</span>
                    </div>
                  </div>

                <div class="mt-6 p-6 rounded-2xl shadow-[0_20px_30px_0px_rgba(231,226,244,0.25)]">
                    <div class="flex justify-between items-center">
                        <div class="flex justify-between items-center gap-2">
                            <img src="@/assets/images/icon-barcode.svg" class="w-6 h-6">
                            <span class="font-semibold">Barcode</span>
                        </div>
                        <img src="@/assets/images/icon-arrow-up.svg" class="w-6 h-6 cursor-pointer" ref="dropdown-barcode" @click="show('barcode')" :class="{ 'rotate-180': showState.barcode }">
                    </div>
                    <div class="flex flex-col text-center gap-2" ref="img-barcode" :class="{ hidden: showState.barcode }">
                      <vue-barcode :value="detail.voucher_id" class="w-full" element-tag="img"/>
                    </div>
                </div>
                <div class="flex flex-col gap-2 items-center mt-8">
                    <!-- <img src="@/assets/images/janji-jiwa.png" class="h-16 w-16 rounded rounded-lg border border-[#EDEDED]"> -->
                    <span class="mt-6">Powered by</span>
                    <img src="@/assets/images/logo-venteny.svg">
                </div>
            </div>
        </div>

        <div class="modal flex flex-col items-center w-full" :class="{ hidden: !showState.modalTnc }" id="modal-terms-conditions">
            <div class="bg-white rounded-2xl p-5 w-full max-w-[480px] relative">
                <img src="@/assets/images/icon-close.svg" alt="" class="w-6 h-6 absolute top-5 right-5 cursor-pointer" id="close-modal-terms-conditions">
                <h2 class="text-base font-bold">Syarat dan ketentuan Voucher Digital Grabfood Janji Jiwa Rp 25.000</h2>
                <ol class="mt-6 text-sm list-decimal pl-4 text-gray">
                    <li>Setiap pembelanjaan produk Tonga Bag dan Batik Semar  minimal Rp 100.000,- di Klikindomaret GRATIS Cashback dengan nilai maksimum Rp 100.000,-.</li>
                    <li>Periode promosi 1-30 April 2017.</li>
                    <li>Konsumen dapat melakukan transaksi berulang selama periode promosi berlangsung.</li>
                    <li>Cashback tidak berlaku untuk cara pembayaran Bayar di tempat / COD (Cash on Delivery).</li>
                    <li>Konsumen yang melakukan pembatalan order / retur tidak akan mendapatkan Cashback.</li>
                    <li>Cashback tidak berlaku untuk kelipatan dan akumulasi transaksi.</li>
                    <li>Hanya berlaku untuk konsumen yang telah melakukan pembayaran LUNAS selama periode promo berlangsung.</li>
                    <li>Satu konsumen (dengan alamat kirim dan nomor handphone yang sama) hanya berhak mendapatkan Cashback maksimum Rp 100.000,-.</li>
                </ol>
            </div>
        </div>

        <div class="modal flex flex-col items-center w-full hidden" id="modal-expired">
            <div class="bg-white rounded-2xl p-5 w-full max-w-[480px] relative">
                <!-- <img src="@/assets/images/icon-close.svg" alt="" class="w-6 h-6 absolute top-5 right-5 cursor-pointer" id="close-modal-expired"> -->
                <div class="flex flex-col items-center gap-6 text-center px-8">
                    <img src="@/assets/images/reedem-success.svg" class="w-52 h-auto">
                    <h2 class="text-base font-bold px-6">Segera gunakan voucher setelah melakukan Redeem saat pembayaran di kasir!</h2>
                    <span class="font-normal text-sm">Bisa digunakan sebelum:</span>
                    <h2 class="font-bold text-4xl text-danger my-[-12px]">00:15:00:10</h2>
                    <span class="font-normal text-sm text-[#FB7418]">*Pastikan melakukan redeem pada saat di kasir</span>
                    <p class="font-normal text-sm text-gray">Dengan menekan tombol Redeem, anda telah setuju untuk segera menggunakan voucher. Bila tidak digunakan, maka voucher akan hangus dan tidak menjadi tanggung jawab VENTENY.</p>
                </div>
                <div class="flex gap-4 mt-12">
                    <button class="w-full rounded-lg py-3 px-4 font-semibold text-brand border border-[#FF8F15]" id="close-modal-expired">Tutup</button>
                    <button class="w-full rounded-lg py-3 px-4 font-semibold bg-[#FF8F15] text-white" id="close-modal-expired">Redeem</button>
                </div>
            </div>
        </div>

        <div class="flex flex-col items-center w-full bg-white" v-if="['claim', 'expired'].includes(detail.status)">
            <div class="w-full max-w-[480px] relative flex flex-col items-center">
                <img src="@/assets/images/icon-close.svg" alt="" class="w-8 h-8 absolute top-0 right-0 cursor-pointer" id="close-modal-voucher-redeemed">

                <img src="@/assets/images/voucher-redeemed.svg" class="w-80 h-auto">
                <h2 class="font-bold text-xl text-danger mt-2">Voucher telah {{ detail.status == 'claim' ? 'di-redeem' : 'kadaluarsa' }}!</h2>
                <div class="flex flex-col w-full py-4">
                    <div class="px-4 py-5 rounded-2xl shadow-[0_20px_30px_0px_rgba(231,226,244,0.25)]">
                        <div class="flex items-center justify-between border-b border-[#EDEDED] pb-4">
                            <span class="font-semibold text-base text-gray">Voucher Code </span>
                            <span class="font-semibold text-base text-brand">{{ detail.code }}</span>
                        </div>
                        <div class="flex items-center justify-between pt-4">
                            <span class="font-normal text-base text-gray">Tanggal {{ detail.status == 'claim' ? 'Redeem' : 'Kadaluarsa' }}</span>
                            <span class="font-normal text-base text-danger">{{ detail.status == 'claim' ? detail.claim_at_str : detail.expired_at_str }}</span>
                        </div>
                    </div>
                    <p class="font-normal text-gray text-center mt-12 px-8">Contact to VENTENY CS if you haven't redeemed the voucher</p>
                    <div class="flex justify-content-center px-28 p-4 m-2">
                        <a href="https://wa.me/62818694800?text=Hai VENTENY, saya butuh bantuan">
                          <img src="@/assets/images/icon-wa.svg" alt="" class="w-8 h-8 mx-2">
                        </a>
                        <a href="mailto:customer_service@venteny.com">
                          <img src="@/assets/images/icon-mail.svg" alt="" class="w-8 h-8 mx-2">
                        </a>
                        <a href="telp:62215206225">
                          <img src="@/assets/images/icon-call.svg" alt="" class="w-8 h-8 mx-2">
                        </a>
                    </div>
                </div>
                <div class="flex flex-col gap-2 items-center mt-20">
                    <span class="font-normal text-xs">Powered by</span>
                    <img src="@/assets/images/logo-venteny.svg">
                </div>
            </div>
        </div>

        <div class="flex flex-col items-center w-full bg-white" v-if="detail.status == 'not_found'">
            <div class="w-full max-w-[480px] relative flex flex-col items-center">
                <img src="@/assets/images/icon-close.svg" alt="" class="w-8 h-8 absolute top-0 right-0 cursor-pointer" id="close-modal-voucher-redeemed">

                <img src="@/assets/images/voucher-redeemed.svg" class="w-80 h-auto">
                <h2 class="font-bold text-xl text-danger mt-2">Voucher tidak ditemukan!</h2>
                <div class="flex flex-col w-full py-4">
                    <div class="px-4 py-5 rounded-2xl shadow-[0_20px_30px_0px_rgba(231,226,244,0.25)]">
                        <div class="flex items-center justify-between border-b border-[#EDEDED] pb-4">
                            <span class="font-semibold text-base text-gray">Voucher Code </span>
                            <span class="font-semibold text-base text-brand">-</span>
                        </div>
                        <div class="flex items-center justify-between pt-4">
                            <span class="font-normal text-base text-gray">Tanggal Kadaluarsa</span>
                            <span class="font-normal text-base text-danger">-</span>
                        </div>
                    </div>
                    <p class="font-normal text-gray text-center mt-12 px-8">Contact to VENTENY CS if you haven't redeemed the voucher</p>
                    <div class="flex justify-content-center px-28 p-4 m-2">
                        <a href="https://wa.me/62818694800?text=Hai VENTENY, saya butuh bantuan">
                          <img src="@/assets/images/icon-wa.svg" alt="" class="w-8 h-8 mx-2">
                        </a>
                        <a href="mailto:customer_service@venteny.com">
                          <img src="@/assets/images/icon-mail.svg" alt="" class="w-8 h-8 mx-2">
                        </a>
                        <a href="telp:62215206225">
                          <img src="@/assets/images/icon-call.svg" alt="" class="w-8 h-8 mx-2">
                        </a>
                    </div>
                </div>
                <div class="flex flex-col gap-2 items-center mt-20">
                    <span class="font-normal text-xs">Powered by</span>
                    <img src="@/assets/images/logo-venteny.svg">
                </div>
            </div>
        </div>
      </section>
    </main>
</template>
<script>
// import VueQrcode from 'qrcode.vue';
import VueBarcode from 'vue-barcode';
import moment from 'moment';
import { REDEEM_VOUCHER } from '../../store/modules/voucher';

const qrcode = require('qrcode');

export default {
  name: 'RedeemVoucher',
  components: {
    // VueQrcode,
    VueBarcode,
  },
  data() {
    return {
      detail: {
        voucher: {},
      },
      showState: {
        barcode: false,
        qrcode: false,
        modalTnc: false,
      },
    };
  },
  mounted() {
    this.redeemVoucherPromo();
  },
  methods: {
    redeemVoucherPromo() {
      const body = {
        id: this.$route.params.voucher_id,
        merchant_id: this.$route.params.merchant_id,
      };
      this.$store.dispatch(REDEEM_VOUCHER, body).then(async (result) => {
        this.detail = result;
        this.detail.expired_at_str = moment(result.expired_at).locale('id').format('DD MMMM YYYY, 23:59');
        this.detail.claim_at_str = moment(result.claim_at).locale('id').format('DD MMMM YYYY, HH:mm');
        const image_qr = await qrcode.toDataURL(result.code);
        this.$set(this.detail, 'image_qr', image_qr);
      }).catch(() => {
        this.detail = {
          status: 'not_found',
        };
        // this.$router.push({ name: '404' });
      });
    },
    async clickToCopy(value) {
      await navigator.clipboard.writeText(value);
      this.$toast({
        message: 'Kode berhasil\ndisalin.',
        className: 'multiline',
      });
    },
    show(type) {
      this.showState[type] = !this.showState[type];
    },
  },
};
</script>
<style scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box
  }

  img {
    max-width: 100%;
  }

  .main-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
  }

  body {
    background-color: #FAFAFC;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    color: #1F272E;
  }

  .body--sk {
    background-color: #ffffff;
  }

  .heading {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
  }

  .info {
    padding: 0px 20px;
    padding-bottom: 100px;
  }

  .info--sk {
    padding: 0px;
    /* padding-bottom: 190px; */
  }

  .card-info {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #E2E2EE;
    transform: translateY(-40px);
  }

  .card-info__heading {
    padding: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  }

  .card-info__datetime {
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 10px 10px 10px 20px;
    color: #FB7418;
    background-color: #FEF3E8;
  }

  .card-info__datetime span {
    display: block;
    padding-left: 10px;
  }

  .card-info__body {
    padding: 15px 20px 25px 20px;
    font-size: 14px;
    line-height: 20px;
  }

  .card-info--sk {
    border-radius: 0px;
    box-shadow: unset;
  }

  .box-location {
    background-color: #FAFAFC;
    padding: 10px 20px 10px 10px;
    display: flex;
    margin: 24px 0px;
  }

  .box-location__info {
    padding-left: 10px;
  }

  .box-location__info .heading {
    margin-bottom: 4px;
  }

  .box-location__info p {
    font-weight: 300;
    line-height: 17.73px;
    font-size: 13px;
  }

  /* .box-validity_period {
    font-size: 16px;
} */

  .action-sk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1F272E;
    margin-bottom: 30px;
  }

  .main-cta {
    padding: 13px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.37px;
    background-color: #FB7418;
    border-radius: 8px;
    display: block;
    color: #ffffff;
    font-weight: 600;
  }

  .main-cta span {
    font-weight: 900;
  }

  .floating-cta {
    background-color: #ffffff;
    box-shadow: 0px -4px 4px 0px #E2E2EE;
    position: fixed;
    padding: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 13px;
    line-height: 20px;
  }

  ul li {
    margin-bottom: 4px;
  }

  .multiline {
    white-space: pre-wrap;
  }

  .text-disabled {
    color: #a19e9e;
  }

  .rotate-180 {
    transform: rotate(180deg)
  }

  .vue-barcode-element {
    width: 450px;
  }

  .text-brand {
    color: #FF8F15
  }

  .text-gray {
    color: #8F8F92
  }
</style>
